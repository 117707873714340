<template>
  <vue-splash
    :show="true"
    :logo="logo"
    title=""
    color="#109afd"
    :size="400"
    :fixed="true"
  />
</template>

<script>
import YourLogo from "../assets/Report.svg";

export default {
  name: "YourMagnificentVueComponent",
  computed: {
    logo() {
      return YourLogo;
    },
  },
};
</script>
