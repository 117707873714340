import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/Index"),
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("@/views/Account/Login"),
      },
    ],
  },
  {
    path: "/",
    name: "Main",
    component: Home,
    beforeEnter: (to, from, next) => {
      // window.document.title = to.name ? i18n.t(to.name) : "UIMS";
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null &&
        localStorage.getItem("token") !== undefined &&
        localStorage.getItem("token") !== "undefined" &&
        localStorage.getItem("token").substr(0, 2) === "ey"
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard"),
      },
      {
        path: "/users",
        name: "users.users",
        component: () => import("../views/Account/Users.vue"),
      },

      {
        path: "/changePassword",
        name: "users.changePassword",
        component: () => import("../views/Account/ChangePassword.vue"),
      },
      {
        path: "/notauthorize",
        name: "notauthorize",
        component: () => import("../views/NotAuthorized.vue"),
      },
      {
        path: "/nodes",
        name: "nodes.nodes",
        component: () => import("../views/Nodes/Index.vue"),
      },
      {
        path: "/products",
        name: "products",
        component: () => import("../views/Products/Index.vue"),
      },
      {
        path: "/reports",
        name: "reports.reports",
        component: () => import("../views/Reports/Index.vue"),
      },
      {
        path: "/requests",
        name: "requests.requests",
        component: () => import("../views/Requests/Index.vue"),
      },
      {
        path: "/show/:requestGuid",
        name: "requests.show",
        component: () => import("../views/Requests/Show.vue"),
      },
      {
        path: "/categories",
        name: "categories.categories",
        component: () => import("../views/Categories/Index.vue"),
      },
    ],
  },

  {
    path: "*",
    component: () => import("@/views/Home"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/Error"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((toRoute) => {
  window.document.title = i18n.t(toRoute.name);
});

export default router;
